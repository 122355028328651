import React from 'react'
import styled from "styled-components"
import { Link } from 'gatsby'
import { CommentCount } from 'gatsby-plugin-disqus'
import { rhythm } from '../Merry/vars'


const Section = styled.section`
    display: flex;
    align-items: end;
    justify-content: space-between;
`

const Description = styled.p`
    margin-bottom: 0;
`

const Comments = styled.span`

`

interface Props {
    node: any
    url: string
}

const Item = ({ node, url }: Props) => (
    <article key={node.fields.slug}>
      <header>
        <h3
          style={{
            marginBottom: rhythm(1 / 4),
          }}
        >
          <Link style={{ boxShadow: `none` }} to={`/post${node.fields.slug}`} state={{ navOpen: false}}>
            {node.frontmatter.title || node.fields.slug}
          </Link>
        </h3>
        <small>{node.frontmatter.date}</small>
      </header>
      <Section>
        <Description
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt,
          }}
        />
        <Comments>
            <CommentCount config={{
                url: url,
                identifier: node.id,
                title: node.frontmatter.title,
            }} />
        </Comments>
      </Section>
    </article>
)

export default Item
