import React from "react"
import { PageProps, graphql } from "gatsby"

import { Main } from '../components/Merry/Layout'
import Item from "../components/Posts/Item"
import PageWrap from "../components/PageWrap"

type Data = {
  site: {
    siteMetadata: {
      title: string
      siteUrl: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        id: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <PageWrap
      title="All posts"
      description=""
      navOpen={true}
    >
      <Main>
        {posts.map(({ node }) => (
            <Item key={node.id} node={node} url={`${data.site.siteMetadata.siteUrl}${location.pathname}`} />
        ))}
      </Main>
    </PageWrap>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {fileAbsolutePath: {regex: "/(blog)/.*\\.md$/"}}
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
